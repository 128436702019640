import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import "../styles/style-about.css";
import { isSSR } from '../utils/utils';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDozMjMx") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const About = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  //
  useEffect(() => {
    if (isSSR()) {
      return;
    }
    //
    const items = document.querySelectorAll('.read-more-button');
    items.forEach((item) => item.addEventListener('click', function () {
      const content = this.parentElement.querySelector('.read-more-content');
      if (content.style.maxHeight === '' || content.style.maxHeight === '0px') {
        content.style.maxHeight = content.scrollHeight + "px";
        this.innerHTML = 'Read Less';
      } else {
        content.style.maxHeight = '0';
        this.innerHTML = 'Read More';
      }
    }));
  }, []);
  return (
    <Layout>
      <Seo title="About" description={common.seo.metaDesc} canonical={common.uri} seo={common.seo} />
      <div class="main-bg">
        <div class="overlay"></div>
        <img src="/assets/images/about1.jpg" alt="" />
        <h1>About Us</h1>
      </div>

      <div class="paramaindiv">
        <div class="sidepara">
          <p>Our team is a rare blend of people with legal and technical expertise from top-tier law firms. The IP
            work by our attorneys has laid the foundations for public companies and emerging unicorns. With
            offices in Silicon Valley, Vietnam, and Kenya, we aim to serve the global IP needs of our clients,
            large companies as well as start-ups.</p>
          <p>As advisors to disruptive companies, especially in the technology and small, medium, high-growth
            sectors, we know first-hand the power that innovation has. We invest significant time and resources
            into developing and implementing the systems and infrastructure that will dramatically improve the
            client experience.</p>
          <p>PatentPC considers you a partner and not a transaction. Our goals are your goals. These are the
            characteristics that make us unique and distinguish us as the go-to IP law firm to safeguard your
            innovations</p>
        </div>
        <div class="paraimg" style={{ marginBottom: 50 + 'px' }}>
          {/* <img src="/assets/images/laptop-2589420_1280-3-1024x681-1.jpg" alt=""/> */}
          <video style={{ minHeight: 275 + 'px', width: 500 + 'px' }} poster="/assets/images/about-thumb.png" preload="metadata" controls>
            <source src="https://wp.patentpc.com/wp-content/uploads/2023/12/patentpc-explainer.mp4" type="video/mp4"></source>
          </video>
        </div>
      </div>

      <div class="paramaindiv bg-intro-about" style={{ display: 'block' }}>
        <div className='row'>
          <div className='col-md-12'>
            <h1>Meet the Team</h1>
            <h3>Focus on your business. We'll protect your IP.</h3>
          </div>
        </div>
        <div className='row'>
          <div class="container">
            <div class="employee-container">
              <div class="row">
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/tranbaov2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Bao Tran<br/><span class="employee-role">Attorney</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/tranquangphuongv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Peter Phuong Tran<br/><span class="employee-role">Attorney</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/Kurt-Brown.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Dr. Kurt Brown<br/><span class="employee-role">Attorney</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/cephas-doc-lawv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Cephas Doc<br/><span class="employee-role">Attorney</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/nguyetrungsonv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Son Nguyen, Ph.D<br/><span class="employee-role">Advisor</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/tran-duc-quyenv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Quyen Tran<br/><span class="employee-role">Patent Agent</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/wanja-buguav2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Wanja Bugua<br/><span class="employee-role">Client Success Manager</span></h1>
                      </div>
                    </div>
                    <div class="employee-details" style={{position: 'relative'}}>

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                      <div className="circular--portrait">
                      <img src="/assets/images/about/v2/maryv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Mary Kimani<br/><span class="employee-role">Client Success Manager</span></h1>
                      </div>
                    </div>
                    <div class="employee-details">

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 employee-1">
                  <div class="employee">
                    <div class="employee-image">
                    <div className="circular--portrait">
                      <img src="/assets/images/about/v2/charity-wanguiv2.png" class="img-fluid d-block m-auto" alt="employee-image"/>
                      </div>
                      <div class="employee-name">
                        <h1 class="text-center">Charity Wangui<br/><span class="employee-role">Client Success Manager</span></h1>
                      </div>
                    </div>
                    <div class="employee-details">

                      <div class="employee-social-link">
                        <ul>
                          <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                          <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                          <li><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          {/* <div className='col-md-12 pb-4'>
            <div>
              <div class="card border-0 bg-intro-about">
                <div className="circular--portrait">
                  <img src="/assets/images/about/tran-bao.png.webp" class="card-img-top card-img-about img-radius" alt="Bao Tran" />
                </div>
                <div class="card-body ps-0 pt-0">
                  <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Bao Tran</h5>
                  <p class="card-text">Attorney</p>
                </div>
              </div>

              <div class="card border-0 bg-intro-about">
                <div className="circular--portrait">
                  <img src="/assets/images/about/tran-quang-phuong.webp" class="card-img-top card-img-about img-radius" alt="Peter Phuong Tran" />
                </div>
                <div class="card-body ps-0 pt-0">
                  <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Peter Phuong Tran</h5>
                  <p class="card-text">Attorney
                  </p>
                </div>
              </div>

              <div class="card border-0 bg-intro-about">
                <div className="circular--portrait">
                  <img src="/assets/images/about/Kurt-Brown.png" class="card-img-top card-img-about img-radius" alt="Dr. Kurt Brown, J.D., Ph.D." />
                </div>
                <div class="card-body ps-0 pt-0">
                  <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Dr. Kurt Brown</h5>
                  <p class="card-text">Attorney
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          {/*  */}
          {/* <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/cephas-doc-law.png" class="card-img-top card-img-about img-radius" alt="Peter Phuong Tran" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Cephas Doc</h5>
                <p class="card-text">Attorney
                </p>
              </div>
            </div>
          </div> */}

          {/*  */}
          {/* <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/nguyen-truong-son.png.webp" class="card-img-top card-img-about img-radius" alt="Nguyễn Trường Sơn" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Son Nguyen, Ph.D.</h5>
                <p class="card-text">Advisor
                </p>
              </div>
            </div>
          </div> */}


          {/*  */}
          {/* <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/tran-duc-quyen.png.webp" class="card-img-top card-img-about img-radius" alt="Duke Quyen" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Quyen Tran</h5>
                <p class="card-text">Patent Agent
                </p>
              </div>
            </div>
          </div>

          <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/wanja-bugua.png" class="card-img-top card-img-about img-radius" alt="Wanja Bugua" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Wanja Bugua</h5>
                <p class="card-text">Client Success Manager
                </p>
              </div>
            </div>
          </div>

          <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/mary.png.webp" class="card-img-top card-img-about img-radius" alt="Mary Kimani" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Mary Kimani</h5>
                <p class="card-text">Client Success Manager
                </p>
              </div>
            </div>
          </div>

          <div className='col-md-4 pb-4'>
            <div class="card border-0 bg-intro-about">
              <img src="/assets/images/about/charity-wangui.png" class="card-img-top card-img-about img-radius" alt="Charity Wangui" />
              <div class="card-body ps-0 pt-0">
                <h5 class="card-title h2" style={{ marginBottom: '10px' }}>Charity Wangui</h5>
                <p class="card-text">Client Success Manager
                </p>
              </div>
            </div>
          </div> */}

        </div>
      </div>


    </Layout>
  );
};

export default About;
//This will not do anything
